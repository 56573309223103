import React from 'react';
import classNames from 'classnames';

import appSettings from '../../../config/settings';

import ExternalLink from '../../ExternalLink/ExternalLink';

import css from './NotificationBar.module.css';

const NotificationBar = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return appSettings.showNotificationBar ? (
    <div className={classes}>
      <p className={css.text}>
        Wondering how Flex suits your idea? Have a call with our marketplace experts and discuss how
        to turn your ideas into reality.
      </p>
      <ExternalLink
        className={css.cta}
        href="https://calendly.com/welcome-to-flex?utm_source=hosted-test&utm_campaign=banner"
        target="_blank"
        rel="noopener"
      >
        Book a call
      </ExternalLink>
    </div>
  ) : null;
};

export default NotificationBar;
